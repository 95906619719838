import { useI18n } from '@braincube/i18n';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
    styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DOMPurify from 'dompurify';

import { installApplication, removeApplication } from '../../../redux/modules/app';
import { getInstalledApplicationsUuid } from '../../../redux/helpers/apps';
import { unselectApp } from '../../../redux/modules/apps';
import MediaStepper from '../../MediaStepper';
import Eula from '../NotInstalledApplication/Eula';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
}));

const AppDetail = ({
    selectedApplication,
    unselectAppFn,
    installedApplicationsUuid,
    isAdmin,
    installApplicationFn,
    removeApplicationFn,
    offers,
    selectedAccessProductInfo,
}) => {
    const [shouldOpenEula, setShouldOpenEula] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const i18n = useI18n();

    const productOffers = useMemo(
        () => offers.filter((offer) => selectedAccessProductInfo.offers.includes(offer.id)),
        [offers, selectedAccessProductInfo.offers]
    );

    const appIsInOffer = useMemo(
        () => productOffers.find((offer) => offer.apps.includes(selectedApplication?.id)),
        [productOffers, selectedApplication]
    );

    const installApp = useCallback(() => {
        installApplicationFn(selectedApplication.id);
        unselectAppFn();
    }, [installApplicationFn, selectedApplication, unselectAppFn]);

    const handleInstallation = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (selectedApplication.eula && selectedApplication.eula !== '') {
                setShouldOpenEula(true);
            } else {
                installApp();
            }
        },
        [installApp, selectedApplication]
    );

    const confirmDelete = useCallback(() => setConfirmDeletion(true), []);
    const unconfirmDelete = useCallback(() => setConfirmDeletion(false), []);

    let buttons;

    if (installedApplicationsUuid.includes(selectedApplication?.id)) {
        buttons = <>{isAdmin && <Button onClick={confirmDelete}>{i18n.tc('dialog.remove')}</Button>}</>;
    } else if (appIsInOffer && isAdmin) {
        buttons = <Button onClick={handleInstallation}>{i18n.tc('common.install')}</Button>;
    }

    const dangerouslySetInnerHTML = useMemo(
        () => ({ __html: DOMPurify.sanitize(selectedApplication?.fulldescription) }),
        [selectedApplication]
    );

    const closeEula = useCallback(() => setShouldOpenEula(false), []);

    const handleRemoveApplication = useCallback(() => {
        removeApplicationFn(selectedApplication?.id);
        unselectAppFn();
    }, [removeApplicationFn, selectedApplication, unselectAppFn]);

    if (!selectedApplication) {
        return null;
    }

    return (
        <>
            <Dialog onClose={unselectAppFn} open={selectedApplication !== null} maxWidth="sm" fullWidth>
                <DialogTitle disableTypography>
                    <Typography variant="h6">{selectedApplication.name}</Typography>
                    <StyledIconButton aria-label="close" onClick={unselectAppFn}>
                        <CloseIcon />
                    </StyledIconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedApplication.fulldescription && (
                        <Typography gutterBottom variant="body2" dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
                    )}
                    {selectedApplication.mediaUrl && selectedApplication.mediaUrl.length > 0 && (
                        <MediaStepper medias={selectedApplication.mediaUrl} />
                    )}
                </DialogContent>
                <DialogActions>{buttons}</DialogActions>
            </Dialog>
            {shouldOpenEula && <Eula eula={selectedApplication.eula} onClose={closeEula} onAccept={installApp} />}
            <Dialog open={confirmDeletion} onClose={unconfirmDelete}>
                <DialogTitle>{i18n.tc('dialog.areYouSure')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {selectedApplication.name}
                        {i18n.tc('dialog.confirmDelete')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={unconfirmDelete}>
                        {i18n.tc('dialog.no')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleRemoveApplication}>
                        {i18n.tc('dialog.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

AppDetail.propTypes = {
    selectedApplication: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        fulldescription: PropTypes.string.isRequired,
        mediaUrl: PropTypes.array.isRequired,
        eula: PropTypes.string,
        productsTarget: PropTypes.array.isRequired,
    }),
    installedApplicationsUuid: PropTypes.arrayOf(PropTypes.string).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    installApplicationFn: PropTypes.func.isRequired,
    removeApplicationFn: PropTypes.func.isRequired,
    unselectAppFn: PropTypes.func.isRequired,
    offers: PropTypes.arrayOf(PropTypes.shape({})),
    selectedAccessProductInfo: PropTypes.shape({
        offers: PropTypes.arrayOf(PropTypes.string),
    }),
};

AppDetail.defaultProps = {
    selectedApplication: null,
    offers: null,
    selectedAccessProductInfo: null,
};

export default connect(
    (state) => ({
        selectedApplication: state.apps.selected,
        installedApplicationsUuid: getInstalledApplicationsUuid(state),
        isAdmin: state.accesses.isAdmin,
        offers: state.offers.list,
        selectedAccessProductInfo: state.accesses.selectedAccessProductInfo,
    }),
    (dispatch) => ({
        installApplicationFn: (uuid) => dispatch(installApplication(uuid)),
        removeApplicationFn: (uuid) => dispatch(removeApplication(uuid)),
        unselectAppFn: () => dispatch(unselectApp()),
    })
)(AppDetail);
