import { useI18n } from '@braincube/i18n';
import React, { useCallback, useMemo, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    styled,
} from '@mui/material';
import PropTypes from 'prop-types';

const StyledContent = styled('div')(({ theme }) => ({
    maxHeight: 500,
    overflow: 'auto',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
}));

const Eula = ({ onClose, onAccept, eula }) => {
    const [eulaAccepted, setEulaAccepted] = useState(false);
    const i18n = useI18n();

    const dangerouslySetInnerHTML = useMemo(() => ({ __html: eula }), [eula]);

    const handleCheckboxChange = useCallback((e) => setEulaAccepted(e.target.checked), []);

    const renderCheckbox = useMemo(
        () => <Checkbox checked={eulaAccepted} onChange={handleCheckboxChange} />,
        [eulaAccepted, handleCheckboxChange]
    );

    return (
        <Dialog open onClose={onClose} maxWidth="xs">
            <DialogTitle>{i18n.tc('eula.title')}</DialogTitle>
            <DialogContent>
                <StyledContent>
                    <Typography align="justify" variant="body2" dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
                </StyledContent>
                <FormControlLabel control={renderCheckbox} label={i18n.tc('eula.readAndAccept')} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{i18n.tc('common.cancel')}</Button>
                <Button color="primary" disabled={!eulaAccepted} variant="contained" onClick={onAccept}>
                    {i18n.tc('common.install')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

Eula.propTypes = {
    eula: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
};

export default Eula;
