import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './modules/reducer';

const isNotProduction = import.meta.env.DEV;

// Use the redux dev tools Chrome/Firefox extention in dev if user has it on his browser.
const enableDevTools = isNotProduction && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const composeEnhancers = enableDevTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

// Use redux-thunk middleware to allow async action creators
const middleware = [thunk];

// When we are in dev mode, log to the console redux actions and state.
if (isNotProduction) {
    middleware.push(logger);
}

const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(reducer, {}, enhancer);

if (import.meta.hot) {
    import.meta.hot.accept('./modules/reducer', () => {
        const nextReducer = require('./modules/reducer').default; // eslint-disable-line global-require

        store.replaceReducer(nextReducer);
    });
}

export default store;
