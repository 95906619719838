import { useI18n } from '@braincube/i18n';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Card, CardActionArea, CardContent, Grid, styled, useTheme } from '@mui/material';
import * as icons from '@braincube/svg';
import FadeInQuickAction from '../../../keyframes';

import { ReactComponent as InstallIcon } from '../../../images/install.svg';
import iotNode from '../../../images/ioTNode.svg';
import { selectApp } from '../../../redux/modules/apps';
import { installApplication } from '../../../redux/modules/app';
import NotReachableLayer from '../../NotReachableLayer';
import Eula from './Eula';

const StyledQuickActions = styled('div')({
    display: 'none',
    top: 0,
    right: 0,
    position: 'absolute',
    width: 45,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    animation: `${FadeInQuickAction} 0.3s 1 ease-in`,
});

const StyledCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    '&:hover': {
        '& .quick-actions': {
            display: 'block',
        },
    },
    height: 220,
    marginBottom: theme.spacing(1),
}));

const StyledCardActionArea = styled(CardActionArea)({
    height: '100%',
});

const StyledCardMedia = styled('div', { shouldForwardProp: (prop) => prop !== 'color' })(({ color }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 120,
    fontSize: 60,
    backgroundColor: color,
}));

const StyledIotNote = styled('div')({
    position: 'absolute',
    left: 0,
    top: 0,
    width: 34,
    height: 34,
    opacity: 0.4,
    backgroundImage: `url(${iotNode})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 20,
});

const StyledCardContent = styled(CardContent)({
    height: 100,
});

const StyledActionInstall = styled('div')(({ theme }) => ({
    width: 45,
    height: 45,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const getAppIcon = (icon, logoColor) => {
    const logoComponent = Object.keys(icons).filter((logo) => {
        return logo.replace('Icon', '') === icon && logo !== 'default';
    });

    let logo = null;

    if (logoComponent.length > 0) {
        logo = React.createElement(icons[logoComponent[0]], { fontSize: 'inherit', htmlColor: logoColor });
    } else {
        logo = <icons.Node fontSize="inherit" htmlColor={logoColor} />;
    }

    return logo;
};

export const NotInstalledApplicationComponent = ({ isAdmin, app, installApplicationFn, selectAppFn, isNotInOffer }) => {
    const theme = useTheme();
    const [shouldOpenEula, setShouldOpenEula] = useState(false);
    const i18n = useI18n();

    const handleInstallation = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (app.eula && app.eula !== '') {
                setShouldOpenEula(true);
            } else {
                installApplicationFn(app.id);
            }
        },
        [app, installApplicationFn]
    );

    const handleSelectApp = useCallback(() => selectAppFn(app.id), [app.id, selectAppFn]);

    const closeEula = useCallback(() => setShouldOpenEula(false), []);

    const acceptEula = useCallback(() => installApplicationFn(app.id), [app.id, installApplicationFn]);

    return (
        <>
            <Grid item xs={6} sm={4} md={4} lg={2}>
                <StyledCard onClick={handleSelectApp}>
                    <StyledCardActionArea>
                        <StyledCardMedia color={app.color}>
                            {getAppIcon(app.icon, theme.palette.getContrastText(app.color))}
                            {app.productsTarget.includes('IOT') && <StyledIotNote />}
                        </StyledCardMedia>
                        <StyledCardContent>
                            <Typography gutterBottom variant="subtitle2">
                                {app.name}
                            </Typography>
                            <Typography variant="caption">{app.description}</Typography>
                        </StyledCardContent>
                        {isNotInOffer && <NotReachableLayer message={i18n.tc('card.notInOffer')} />}
                    </StyledCardActionArea>
                    {!isNotInOffer && isAdmin && (
                        <StyledQuickActions className="quick-actions">
                            <StyledActionInstall role="button" tabIndex={0} onClick={handleInstallation}>
                                <InstallIcon width={25} />
                            </StyledActionInstall>
                        </StyledQuickActions>
                    )}
                </StyledCard>
            </Grid>
            {shouldOpenEula && <Eula eula={app.eula} onClose={closeEula} onAccept={acceptEula} />}
        </>
    );
};

NotInstalledApplicationComponent.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    app: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        productsTarget: PropTypes.array.isRequired,
        eula: PropTypes.string,
    }).isRequired,
    installApplicationFn: PropTypes.func.isRequired,
    selectAppFn: PropTypes.func.isRequired,
    isNotInOffer: PropTypes.bool.isRequired,
};

export default connect(
    (state) => ({
        isAdmin: state.accesses.isAdmin,
    }),
    (dispatch) => ({
        installApplicationFn: (appUuid) => dispatch(installApplication(appUuid)),
        selectAppFn: (uuid) => dispatch(selectApp(uuid)),
    })
)(NotInstalledApplicationComponent);
