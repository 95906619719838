import { useI18n } from '@braincube/i18n';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Typography,
    CircularProgress,
    Grid,
    CardActionArea,
    Card,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Dialog,
    Tooltip,
    styled,
    useTheme,
} from '@mui/material';

import Info from '@mui/icons-material/InfoOutlined';
import Update from '@mui/icons-material/Update';
import Delete from '@mui/icons-material/DeleteOutline';
import ErrorIcon from '@mui/icons-material/Error';
import SystemUpdateAlt from '@mui/icons-material/SystemUpdateAlt';
import { EditShareMode } from '@braincube/svg';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FadeInQuickAction from '../../../keyframes';

import { selectApp } from '../../../redux/modules/apps';
import { updateApplication, removeApplication } from '../../../redux/modules/app';
import { getAppIcon } from '../../Content/NotInstalledApplication';
import NotReachableLayer from '../../NotReachableLayer';
import ShareDialog from './shareDialog';
import { getDeprecatedInstalledApplications } from '../../../redux/helpers/apps';

const StyledAction = styled('div')(({ theme }) => ({
    width: 35,
    height: 35,
    marginBottom: 1,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledEditShareMode = styled(EditShareMode)({
    '& > .st0': {
        fill: 'none',
    },
});

const StyledPending = styled('div')(({ theme }) => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
}));

const StyledDeprecated = styled('div')(({ theme }) => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    color: '#F00',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
}));

const StyledQuickActions = styled('div')({
    display: 'none',
    top: 0,
    right: 0,
    position: 'absolute',
    width: 35,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    animation: `${FadeInQuickAction} 0.3s 1 ease-in`,
});

const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'backgroundColor' })(({ backgroundColor }) => ({
    backgroundColor,
    position: 'relative',
    '&:hover': {
        '& .quick-actions': {
            display: 'block',
        },
    },
    height: 150,
}));

const StyledCardActionArea = styled(CardActionArea)({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    '&:hover': {
        '& .quick-actions': {
            display: 'block',
        },
    },
});

const StyledCardAppIcon = styled('div')({
    display: 'flex',
    height: '60%',
    alignItems: 'center',
    fontSize: 60,
});

const StyledCardAppName = styled('div', { shouldForwardProp: (prop) => prop !== 'color' })(({ color }) => ({
    color,
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    height: '40%',
    alignItems: 'center',
}));

const APP_STATE = {
    PENDING_INSTALL: 'PENDING_INSTALL',
    PENDING_UNINSTALL: 'PENDING_UNINSTALL',
    PENDING_UPDATE: 'PENDING_UPDATE',
    ERROR_INSTALL: 'ERROR_INSTALL',
    ERROR_UNINSTALL: 'ERROR_UNINSTALL',
    ERROR_UPDATE: 'ERROR_UPDATE',
    INSTALLED: 'INSTALLED',
};

export const InstalledApplicationComponent = ({
    isAdmin,
    app,
    state,
    removeApplicationFn,
    updateApplicationFn,
    selectApplicationFn,
    isSelectedBoxIoT,
    deprecatedInstalledApplications,
}) => {
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const [forceDelete, setForceDelete] = useState(false);
    const theme = useTheme();
    const i18n = useI18n();

    const handleClickOpen = useCallback(() => {
        setShareDialogOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setShareDialogOpen(false);
    }, []);

    const triggerDelete = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setConfirmDeletion(true);
        setForceDelete(false);
    }, []);

    const openShareDialog = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClickOpen();
        },
        [handleClickOpen]
    );

    const triggerUpdateApplication = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            updateApplicationFn(app.id);
        },
        [app.id, updateApplicationFn]
    );

    const showInfos = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            selectApplicationFn(app.id);
        },
        [app.id, selectApplicationFn]
    );

    const confirmDelete = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setConfirmDeletion(true);
        setForceDelete(true);
    }, []);

    const renderQuickAction = (error = false) => {
        return (
            <StyledQuickActions className="quick-actions">
                {!error && isAdmin && (
                    <>
                        <Tooltip title={i18n.tc('card.tooltip.delete')}>
                            <StyledAction role="button" tabIndex={0} onClick={triggerDelete}>
                                <Delete htmlColor="#FFF" />
                            </StyledAction>
                        </Tooltip>
                        {!isSelectedBoxIoT && (
                            <Tooltip title={i18n.tc('card.tooltip.permissions')}>
                                <StyledAction role="button" tabIndex={0} onClick={openShareDialog}>
                                    <StyledEditShareMode htmlColor="#FFF" />
                                </StyledAction>
                            </Tooltip>
                        )}
                        {deprecatedInstalledApplications.some((application) => application.appId === app.id) && (
                            <Tooltip title={i18n.tc('card.tooltip.update')}>
                                <StyledAction role="button" tabIndex={0} onClick={triggerUpdateApplication}>
                                    <Update htmlColor="#FFF" />
                                </StyledAction>
                            </Tooltip>
                        )}
                    </>
                )}
                {!error && (
                    <Tooltip title={i18n.tc('card.tooltip.info')}>
                        <StyledAction role="button" tabIndex={0} onClick={showInfos}>
                            <Info htmlColor="#FFF" />
                        </StyledAction>
                    </Tooltip>
                )}
                {error && (
                    <Tooltip title={i18n.tc('card.tooltip.delete')}>
                        <StyledAction role="button" tabIndex={0} onClick={confirmDelete}>
                            <DeleteForeverIcon htmlColor="#FFF" />
                        </StyledAction>
                    </Tooltip>
                )}
            </StyledQuickActions>
        );
    };

    let layer;

    switch (state) {
        case APP_STATE.PENDING_INSTALL:
        case APP_STATE.PENDING_UNINSTALL:
        case APP_STATE.PENDING_UPDATE:
            layer = (
                <StyledPending>
                    <CircularProgress size={24} color="inherit" />
                </StyledPending>
            );
            break;
        case APP_STATE.ERROR_INSTALL:
        case APP_STATE.ERROR_UNINSTALL:
        case APP_STATE.ERROR_UPDATE:
            layer = (
                <>
                    <StyledPending>
                        <ErrorIcon color="inherit" />
                    </StyledPending>
                    {renderQuickAction(true)}
                </>
            );
            break;
        default:
            if (deprecatedInstalledApplications.some((application) => application.appId === app.id)) {
                layer = (
                    <>
                        <StyledDeprecated>
                            <SystemUpdateAlt color="inherit" />
                        </StyledDeprecated>
                        {renderQuickAction(false)}
                    </>
                );
            } else {
                layer = (
                    <>
                        {app.reachable && renderQuickAction()}
                        {!app.reachable && <NotReachableLayer message={i18n.tc('card.disabled')} />}
                    </>
                );
            }
    }

    const closeConfirmDelete = useCallback(() => setConfirmDeletion(false), []);

    const triggerRemoveApplication = useCallback(() => {
        removeApplicationFn(app.id, forceDelete);
        closeConfirmDelete();
    }, [app.id, closeConfirmDelete, forceDelete, removeApplicationFn]);

    return (
        <Grid item xs={6} sm={4} md={2} lg={1}>
            <StyledCard backgroundColor={app.color}>
                <StyledCardActionArea>
                    <StyledCardAppIcon>
                        {getAppIcon(app.icon, theme.palette.getContrastText(app.color), 60)}
                    </StyledCardAppIcon>
                    <StyledCardAppName color={theme.palette.getContrastText(app.color)}>
                        <Typography variant="caption">{app.name}</Typography>
                    </StyledCardAppName>
                </StyledCardActionArea>
                {layer}
            </StyledCard>
            {shareDialogOpen && <ShareDialog onClose={handleClose} app={app} />}
            <Dialog open={confirmDeletion} onClose={closeConfirmDelete}>
                <DialogTitle>{i18n.tc('dialog.areYouSure')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {app.name}
                        {i18n.tc('dialog.confirmDelete')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={closeConfirmDelete}>
                        {i18n.tc('dialog.no')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={triggerRemoveApplication}>
                        {i18n.tc('dialog.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

InstalledApplicationComponent.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    app: PropTypes.shape({
        id: PropTypes.string.isRequired,
        reachable: PropTypes.bool.isRequired,
        latestVersion: PropTypes.object.isRequired,
        color: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    state: PropTypes.string.isRequired,
    removeApplicationFn: PropTypes.func.isRequired,
    updateApplicationFn: PropTypes.func.isRequired,
    selectApplicationFn: PropTypes.func.isRequired,
    isSelectedBoxIoT: PropTypes.bool.isRequired,
    deprecatedInstalledApplications: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
    (state) => ({
        isAdmin: state.accesses.isAdmin,
        isSelectedBoxIoT: state.accesses.isSelectedBoxIoT,
        deprecatedInstalledApplications: getDeprecatedInstalledApplications(state),
    }),
    (dispatch) => ({
        removeApplicationFn: (appUuid, forceRemove) => dispatch(removeApplication(appUuid, forceRemove)),
        updateApplicationFn: (appUuid) => dispatch(updateApplication(appUuid)),
        selectApplicationFn: (uuid) => dispatch(selectApp(uuid)),
    })
)(InstalledApplicationComponent);
