import { storeProductUuid } from '@braincube/jwt-browser';
import Ws from '../../ws';
import { getAvailableApplications } from './apps';
import { resetFilter } from './filters';

export const SET_ACCESSES = 'app-store-front/accesses/SET_ACCESSES';
export const SET_SELECTED_ACCESS_PRODUCT_UUID = 'app-store-front/accesses/SET_SELECTED_ACCESS_PRODUCT_UUID';
export const SET_SELECTED_ACCESS_PRODUCT_NAME = 'app-store-front/accesses/SET_SELECTED_ACCESS_PRODUCT_NAME';
export const SET_SELECTED_ACCESS_PRODUCT_PROVIDER_NAME =
    'app-store-front/accesses/SET_SELECTED_ACCESS_PRODUCT_PROVIDER_NAME';
export const SET_SELECTED_ACCESS_PRODUCT_INFO = 'app-store-front/accesses/SET_SELECTED_ACCESS_PRODUCT_INFO';
export const SET_USER = 'app-store-front/accesses/SET_USER';
export const SET_IS_SELECTED_BOX_OFFLINE = 'app-store-front/accesses/SET_IS_SELECTED_BOX_ONLINE';
export const SET_IS_SELECTED_BOX_IOT = 'app-store-front/accesses/SET_IS_SELECTED_BOX_IOT';

const parameters = new URLSearchParams(window.location.search);
const uuidUrlParameter = parameters.get('productUuid');

const initialState = {
    list: [],
    selectedProductName: null,
    selectedProductProviderName: null,
    selectedProductUuid: uuidUrlParameter || null || '',
    selectedAccessProductInfo: null,
    offers: null,
    isAdmin: false,
    user: null,
    isSelectedBoxOffline: null,
    isSelectedBoxIoT: null,
};

const isAdmin = ({ accessList, user, selectedProductUuid }) =>
    user.rootAdmin ||
    ['IPLWADMIN', 'COACH', 'ADMIN'].includes(
        accessList.find(({ product }) => product.productId === selectedProductUuid).accessRight
    );

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACCESSES:
            return {
                ...state,
                list: action.payload,
            };
        case SET_USER:
            return { ...state, user: action.payload };
        case SET_SELECTED_ACCESS_PRODUCT_NAME:
            return { ...state, selectedProductName: action.payload };
        case SET_SELECTED_ACCESS_PRODUCT_PROVIDER_NAME:
            return { ...state, selectedProductProviderName: action.payload };
        case SET_SELECTED_ACCESS_PRODUCT_UUID:
            return {
                ...state,
                selectedProductUuid: action.payload,
                isAdmin: isAdmin({
                    accessList: state.list,
                    user: state.user,
                    selectedProductUuid: action.payload,
                }),
            };
        case SET_SELECTED_ACCESS_PRODUCT_INFO:
            return {
                ...state,
                selectedAccessProductInfo: action.payload,
            };

        case SET_IS_SELECTED_BOX_OFFLINE:
            return {
                ...state,
                isSelectedBoxOffline: action.payload,
            };

        case SET_IS_SELECTED_BOX_IOT:
            return {
                ...state,
                isSelectedBoxIoT: action.payload,
            };
        default:
            return state;
    }
}

function startFetching() {
    return {
        type: 'app-store-front/app/START_FETCHING',
    };
}

function stopFetching() {
    return {
        type: 'app-store-front/app/STOP_FETCHING',
    };
}

export function setUser(user) {
    return {
        type: SET_USER,
        payload: user,
    };
}

export function setIsSelectedBoxOffline(isSelectedBoxOffline) {
    return {
        type: SET_IS_SELECTED_BOX_OFFLINE,
        payload: isSelectedBoxOffline,
    };
}

export function setIsSelectedBoxIoT(isSelectedBoxIoT) {
    return {
        type: SET_IS_SELECTED_BOX_IOT,
        payload: isSelectedBoxIoT,
    };
}

export function setAccesses(accesses) {
    return {
        type: SET_ACCESSES,
        payload: accesses,
    };
}

export function setSelectedAccess(access) {
    return (dispatch) => {
        dispatch(getAvailableApplications(access.productId));
        dispatch(resetFilter());
        dispatch({
            type: SET_SELECTED_ACCESS_PRODUCT_UUID,
            payload: access.productId,
        });
        dispatch({
            type: SET_SELECTED_ACCESS_PRODUCT_NAME,
            payload: access.name,
        });
        dispatch({
            type: SET_SELECTED_ACCESS_PRODUCT_PROVIDER_NAME,
            payload: access.providerName,
        });
    };
}

export function setSelectedAccessProductInfo(selectedAccessProductInfo) {
    return {
        type: SET_SELECTED_ACCESS_PRODUCT_INFO,
        payload: selectedAccessProductInfo,
    };
}

export function getAccessProductInfo() {
    return (dispatch, getState) => {
        const { selectedProductUuid } = getState().accesses;

        dispatch(startFetching());

        if (selectedProductUuid !== '') {
            Ws.getProductInfo(selectedProductUuid)
                .then((response) => {
                    dispatch(stopFetching());
                    dispatch(setSelectedAccessProductInfo(response));
                })
                .catch(() => {
                    dispatch(stopFetching());
                });
        }
    };
}

export function initHeaderInfo(me, selected) {
    return (dispatch) => {
        const url = new URL(window.location.href);
        url.searchParams.set('productUuid', selected.product.productId);
        url.searchParams.set('siteUuid', selected.product.site.siteUuid);
        window.history.replaceState({}, '', url.toString());

        storeProductUuid(selected.product.productId);
        dispatch(setUser(me.user));
        dispatch(setAccesses(me.accessList));
        dispatch(
            setIsSelectedBoxOffline(
                me.accessList
                    .find((products) => products.product.name === selected.product.name)
                    .product.data.includes('offline')
            )
        );
        dispatch(
            setIsSelectedBoxIoT(
                me.accessList.find((products) => products.product.name === selected.product.name).product.type === 'iot'
            )
        );
        dispatch(setSelectedAccess(selected.product));
    };
}
