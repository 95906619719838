import Ws from '../../ws';

export const SET_AVAILABLE_APPS = 'app-store-front/apps/SET_AVAILABLE_APPS';
export const SELECT_APP = 'app-store-front/apps/SELECT_APP';
export const UNSELECT_APP = 'app-store-front/apps/UNSELECT_APP';

const initialState = {
    list: [],
    selected: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_AVAILABLE_APPS:
            return { ...state, list: action.payload };
        case SELECT_APP:
            return { ...state, selected: state.list.find((app) => app.id === action.payload) };
        case UNSELECT_APP:
            return { ...state, selected: null };
        default:
            return state;
    }
}

export function setAvailableApps(apps) {
    return {
        type: SET_AVAILABLE_APPS,
        payload: apps,
    };
}

export function selectApp(uuid) {
    return {
        type: SELECT_APP,
        payload: uuid,
    };
}

export function unselectApp() {
    return {
        type: UNSELECT_APP,
    };
}

function stopFetching() {
    return {
        type: 'app-store-front/app/STOP_FETCHING',
    };
}

export function getAvailableApplications(productUuid) {
    return (dispatch) => {
        Promise.all([
            new Promise((resolve, reject) => {
                Ws.getAvailableApplications(productUuid).then(resolve).catch(reject);
            }),
            new Promise((resolve, reject) => {
                Ws.getCustomApplication(productUuid).then(resolve).catch(reject);
            }),
        ])
            .then(([apps, customApps]) => {
                const appCustomIds = customApps.map((app) => app.id);

                dispatch(
                    setAvailableApps(
                        apps.map((app) => {
                            return {
                                ...app,
                                custom: appCustomIds.includes(app.id),
                            };
                        })
                    )
                );
                dispatch(stopFetching());
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    };
}
