import { combineReducers } from 'redux';

import app from './app';
import apps from './apps';
import filters from './filters';
import offers from './offers';
import accesses from './accesses';

export default combineReducers({
    app,
    apps,
    filters,
    offers,
    accesses,
});
