import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, styled } from '@mui/material';

import PropTypes from 'prop-types';
import { getAccessProductInfo } from '../../redux/modules/accesses';
import { initStompClient } from '../../redux/modules/app';

import Header from '../Header';
import Content from '../Content';
import Loader from '../Loader/Loader';

const StyledContainer = styled('div')({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
});

const StyledRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: '100vh',
});

const App = ({
    getAccessProductInfoFn,
    initStompClientFn,
    accessList,
    selectedAccessProductUuid,
    selectedAccessProductInfo,
    isFetching,
    stompClient,
}) => {
    useEffect(() => {
        initStompClientFn();
    }, [initStompClientFn]);

    useEffect(() => {
        getAccessProductInfoFn();
    }, [getAccessProductInfoFn, selectedAccessProductUuid]);

    if (
        (isFetching && accessList.length === 0 && selectedAccessProductUuid === '') ||
        selectedAccessProductInfo === null ||
        stompClient === null
    ) {
        return (
            <StyledContainer>
                <CircularProgress color="inherit" />
            </StyledContainer>
        );
    }
    if (accessList.length > 0 && selectedAccessProductUuid !== '') {
        return (
            <StyledRoot>
                <Loader />
                <Header />
                <Content />
            </StyledRoot>
        );
    }
    return null;
};

App.propTypes = {
    getAccessProductInfoFn: PropTypes.func.isRequired,
    initStompClientFn: PropTypes.func.isRequired,
    accessList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedAccessProductUuid: PropTypes.string.isRequired,
    selectedAccessProductInfo: PropTypes.shape({}),
    isFetching: PropTypes.bool.isRequired,
    stompClient: PropTypes.shape({}),
};

App.defaultProps = {
    selectedAccessProductInfo: null,
    stompClient: null,
};

export default connect(
    (state) => ({
        accessList: state.accesses.list,
        selectedAccessProductUuid: state.accesses.selectedProductUuid,
        selectedAccessProductInfo: state.accesses.selectedAccessProductInfo,
        isFetching: state.app.isFetching,
        stompClient: state.app.stompClient,
    }),
    (dispatch) => ({
        getAccessProductInfoFn: () => dispatch(getAccessProductInfo()),
        initStompClientFn: () => dispatch(initStompClient()),
    })
)(App);
