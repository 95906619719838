import { useI18n } from '@braincube/i18n';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider, Chip, Grid, FormControl, InputLabel, Select, MenuItem, styled } from '@mui/material';
import { buildOptions, buildTheme } from '@braincube/theme';
import { Search } from '@braincube/ui';

import { getCategoriesList, getTagsList } from '../../../redux/helpers/apps';

import { setText, setCategory, updateTags } from '../../../redux/modules/filters';

const muiTheme = buildTheme(
    undefined,
    buildOptions({
        palette: {
            mode: 'dark',
        },
    })
);

const StyledContentSearch = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey[900],
    padding: theme.spacing(1),
}));

const TagItem = ({ tag, tagsFilter, onSelect }) => {
    const selectTag = useCallback(() => onSelect(tag), [onSelect, tag]);

    return (
        <Grid item>
            <Chip label={tag} onClick={selectTag} color={tagsFilter.indexOf(tag) !== -1 ? 'primary' : 'default'} />
        </Grid>
    );
};

TagItem.propTypes = {
    tag: PropTypes.string.isRequired,
    tagsFilter: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
};

TagItem.defaultProps = {
    tagsFilter: [],
};

export const FiltersComponent = ({
    tagsFilter,
    setTagsFilter,
    tagsList,
    textFilter,
    setTextFilter,
    categoryFilter,
    setCategoryFilter,
    categoriesList,
}) => {
    const i18n = useI18n();
    const triggerCategoryChange = useCallback((event) => setCategoryFilter(event.target.value), [setCategoryFilter]);

    const selectTag = useCallback((tag) => setTagsFilter(tag), [setTagsFilter]);

    return (
        <ThemeProvider theme={muiTheme}>
            <StyledContentSearch>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6} md={2}>
                        <Search
                            onChange={setTextFilter}
                            searchValue={textFilter}
                            label={i18n.tc('common.search')}
                            minChars={0}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FormControl fullWidth>
                            <InputLabel>{i18n.tc('search.category')}</InputLabel>
                            <Select
                                label={i18n.tc('search.category')}
                                value={categoryFilter}
                                onChange={triggerCategoryChange}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {categoriesList.map((category) => (
                                    <MenuItem key={category} value={category}>
                                        {category}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={1}>
                            {tagsList.map((tag) => (
                                <TagItem key={tag} tag={tag} tagsFilter={tagsFilter} onSelect={selectTag} />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </StyledContentSearch>
        </ThemeProvider>
    );
};

FiltersComponent.propTypes = {
    textFilter: PropTypes.string.isRequired,
    setTextFilter: PropTypes.func.isRequired,
    tagsFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
    setTagsFilter: PropTypes.func.isRequired,
    tagsList: PropTypes.arrayOf(PropTypes.string).isRequired,
    categoryFilter: PropTypes.string.isRequired,
    setCategoryFilter: PropTypes.func.isRequired,
    categoriesList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(
    (state) => ({
        textFilter: state.filters.text,
        categoryFilter: state.filters.category,
        tagsFilter: state.filters.tags,
        categoriesList: getCategoriesList(state),
        tagsList: getTagsList(state),
    }),
    (dispatch) => ({
        setTextFilter: (textFilter) => dispatch(setText(textFilter)),
        setCategoryFilter: (categoryFilter) => dispatch(setCategory(categoryFilter)),
        setTagsFilter: (tags) => dispatch(updateTags(tags)),
    })
)(FiltersComponent);
