import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, styled } from '@mui/material';

const StyledDots = styled('div')({
    position: 'relative',
    padding: '20px 0 28px',
});

const StyledPaper = styled(Paper, { shouldForwardProp: (prop) => prop !== 'opacity' })(({ theme, opacity }) => ({
    opacity,
    width: 8,
    height: 8,
    backgroundColor: theme.palette.common.black,
    transition: 'all 400ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    borderRadius: 4,
}));

const StyledDotOuter = styled('div', { shouldForwardProp: (prop) => prop !== 'left' || prop !== 'cursor' })(
    ({ left, cursor }) => ({
        left,
        cursor,
        width: 8,
        height: 8,
        padding: 4,
        float: 'left',
        position: 'absolute',
    })
);

const DotButton = ({ iterator, index, previousIndex, onClick }) => {
    const handleClick = useCallback(() => onClick(iterator), [iterator, onClick]);

    return (
        <StyledDotOuter
            key={iterator}
            left={iterator * 16}
            cursor={onClick ? 'pointer' : 'inherit'}
            onClick={handleClick}
        >
            <StyledPaper
                elevation={0}
                opacity={
                    iterator >= Math.min(previousIndex, index) && iterator <= Math.max(previousIndex, index) ? 0.5 : 0.2
                }
            />
        </StyledDotOuter>
    );
};

DotButton.propTypes = {
    iterator: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    previousIndex: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

const StyledCarouselButtonsRoot = styled('div', { shouldForwardProp: (prop) => prop !== 'count' })(({ count }) => ({
    margin: 'auto',
    width: count * 16,
}));

const StyledCarouselButtonsPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'previousIndex' || prop !== 'index',
})(({ previousIndex, index }) => ({
    position: 'absolute',
    marginTop: 4,
    left: Math.min(previousIndex, index) * 16 + 4,
    width: Math.abs(previousIndex - index) * 16 + 8,
}));

const CarouselButtons = ({ index, count, onDotClick }) => {
    const [previousIndex, setPreviousIndex] = useState(index);

    const handleDotClick = useCallback(
        (i) => {
            onDotClick(i);

            setTimeout(() => {
                setPreviousIndex(i);
            }, 450);
        },
        [onDotClick]
    );

    return (
        <StyledCarouselButtonsRoot count={count}>
            <StyledDots>
                {[...Array(count).keys()].map((i) => (
                    <DotButton
                        key={`dot-button-${i}`}
                        iterator={i}
                        onClick={handleDotClick}
                        previousIndex={previousIndex}
                        index={index}
                    />
                ))}
                <StyledCarouselButtonsPaper elevation={0} previousIndex={previousIndex} index={index} />
            </StyledDots>
        </StyledCarouselButtonsRoot>
    );
};

CarouselButtons.propTypes = {
    count: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onDotClick: PropTypes.func.isRequired,
};

export default CarouselButtons;
