/* eslint-disable max-nested-callbacks */

import { useMe } from '@braincube/header';
import { storeProductUuid } from '@braincube/jwt-browser';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Factory, Edgebox } from '@braincube/svg';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Typography, Popover, styled } from '@mui/material';

import { get, set } from './cookies';

const COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID = 'braincube-header-environment-uuid';

const StyledListItem = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:hover': {
        backgroundColor: '#FDE000',
    },
}));

const StyledListItemRightSite = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

const StyledCurrent = styled('div')(({ theme }) => ({
    margin: theme.spacing(0, 1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2.5),
        flex: 1,
        overflow: 'hidden',
    },
}));

const StyledList = styled('ul')({
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    maxHeight: 300,
});

const StyledContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'isSingleAccess' })(
    ({ theme, isSingleAccess }) => ({
        cursor: isSingleAccess ? 'default' : 'pointer',
        display: 'flex',
        backgroundColor: theme.palette.grey[200],
        borderRight: `1px solid ${theme.palette.grey[500]}`,
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        height: theme.header.height,
        flex: 1,
        maxWidth: 340,
        [theme.breakpoints.down('sm')]: {
            flex: 'initial',
            flexShrink: 0,
            borderRight: 'none',
            paddingLeft: theme.spacing(2),
        },
    })
);

const ProductItem = ({ access, onChange }) => {
    const triggerChange = useCallback(() => onChange(access), [access, onChange]);

    return (
        <StyledListItem key={access.product.productId} onClick={triggerChange}>
            {access.product.type === 'braincube' ? <Factory /> : <Edgebox />}
            <StyledListItemRightSite>
                <Typography variant={access.product.type === 'iot' ? 'body2' : 'body1'} noWrap>
                    {access.product.name}
                </Typography>
                {access.product.type === 'iot' && (
                    <Typography variant="caption" noWrap>
                        {`SN: ${access.product.productId}`}
                    </Typography>
                )}
            </StyledListItemRightSite>
        </StyledListItem>
    );
};

ProductItem.propTypes = {
    access: PropTypes.shape({
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            productId: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

const popoverProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

const ProductSelector = ({ onChange }) => {
    const [infos, setInfos] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const me = useMe();

    const closePopover = useCallback(() => setAnchorEl(null), []);

    useEffect(() => {
        const filteredAccessList = me.accessList.filter((access) =>
            ['braincube', 'iot', 'plateform'].includes(access.product.type)
        );

        const meCopy = {
            ...me,
            accessList: filteredAccessList,
        };

        const parameters = new URLSearchParams(window.location.search);
        const uuidUrlParameter = parameters.get('productUuid');
        const cookie = get(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID);

        if (uuidUrlParameter) {
            const finded = filteredAccessList.find((access) => access.product.productId === uuidUrlParameter);

            if (finded) {
                set(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID, finded.product.productId);

                setInfos({
                    me: meCopy,
                    selected: finded,
                });
            } else {
                set(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID, filteredAccessList[0].product.productId);

                setInfos({
                    me: meCopy,
                    selected: filteredAccessList[0],
                });
            }
        } else {
            const finded = filteredAccessList.find((access) => access.product.productId === cookie);

            if (finded) {
                setInfos({
                    me: meCopy,
                    selected: finded,
                });
            } else {
                set(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID, filteredAccessList[0].product.productId);

                setInfos({
                    me: meCopy,
                    selected: filteredAccessList[0],
                });
            }
        }
    }, [me]);

    useEffect(() => {
        if (infos !== null) {
            storeProductUuid(infos.selected.product.productId);
            onChange(infos);
        }
    }, [infos, onChange]);

    const isSingleAccess = useMemo(() => infos !== null && infos.me.accessList.length === 1, [infos]);

    const openProductSelector = useCallback(
        (event) => (isSingleAccess ? null : setAnchorEl(event.currentTarget)),
        [isSingleAccess]
    );

    const changeProduct = useCallback(
        (access) => {
            closePopover();

            set(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID, access.product.productId);

            setInfos({
                me: infos?.me,
                selected: access,
            });
        },
        [closePopover, infos]
    );

    return (
        <>
            <StyledContainer isSingleAccess={isSingleAccess} onClick={openProductSelector}>
                {infos !== null && (infos.selected.product.type === 'braincube' ? <Factory /> : <Edgebox />)}

                <StyledCurrent>
                    {infos === null && <LinearProgress color="secondary" />}

                    {infos !== null && (
                        <>
                            <Typography variant="subtitle2" noWrap>
                                {infos.selected.product.name}
                            </Typography>
                            {infos.selected.product.type === 'iot' && (
                                <Typography variant="caption" noWrap>
                                    {`SN: ${infos.selected.product.productId}`}
                                </Typography>
                            )}
                        </>
                    )}
                </StyledCurrent>
                {!isSingleAccess && <KeyboardArrowDownIcon />}
            </StyledContainer>

            {infos !== null && (
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={popoverProps.anchorOrigin}
                    transformOrigin={popoverProps.transformOrigin}
                    marginThreshold={0}
                >
                    <StyledList>
                        {infos.me.accessList
                            .sort((a, b) => {
                                return a.product.name.trim().localeCompare(b.product.name.trim());
                            })
                            .filter((access) => access.product.productId !== infos.selected.product.productId)
                            .map((access) => (
                                <ProductItem key={access.product.productId} access={access} onChange={changeProduct} />
                            ))}
                    </StyledList>
                </Popover>
            )}
        </>
    );
};

ProductSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default ProductSelector;
