import { useI18n } from '@braincube/i18n';
import { EdgeFactorySupervision } from '@braincube/svg';
import { Apps, FileDownload, Update } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { getDeprecatedInstalledApplications } from '../../../redux/helpers/apps';
import { updateApplication } from '../../../redux/modules/app';
import Ws from '../../../ws';

const Menu = ({
    user,
    isAdmin,
    isSelectedBoxOffline,
    deprecatedInstalledApplications,
    selectedProductUuid,
    updateApplicationFn,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const admin = useCallback(() => {
        window.open(`https://admin.${window.BC_API_ENDPOINTS_CONF.base}/apps`);
    }, []);

    const supervision = useCallback(() => {
        window.open(`https://cdn.${window.BC_API_ENDPOINTS_CONF.base}/edge-factory-supervision/`);
    }, []);

    const downloadApps = useCallback(() => {
        Ws.getZip(selectedProductUuid)
            .then(() => {
                enqueueSnackbar(i18n.tc('notifications.menu.downloadApplicationsSuccess'), { variant: 'success' });
            })
            .catch(() => {
                enqueueSnackbar(i18n.tc('notifications.menu.downloadApplicationsFail'), { variant: 'error' });
            });
    }, [enqueueSnackbar, i18n, selectedProductUuid]);

    const triggerApplicationUpdate = useCallback(
        () => updateApplicationFn(deprecatedInstalledApplications.map((app) => app.appId)),
        [deprecatedInstalledApplications, updateApplicationFn]
    );

    if (user === null) {
        return null;
    }

    return (
        <>
            {user.rootAdmin && (
                <ListItemButton onClick={admin}>
                    <ListItemIcon>
                        <Apps />
                    </ListItemIcon>
                    <ListItemText primary={i18n.tc('menu.appsAdministration')} />
                </ListItemButton>
            )}
            <ListItemButton onClick={supervision}>
                <ListItemIcon>
                    <EdgeFactorySupervision />
                </ListItemIcon>
                <ListItemText primary="IoT Server Supervision" />
            </ListItemButton>
            {isSelectedBoxOffline && (
                <ListItemButton onClick={downloadApps}>
                    <ListItemIcon>
                        <FileDownload />
                    </ListItemIcon>
                    <ListItemText primary={i18n.tc('menu.downloadApplications')} />
                </ListItemButton>
            )}
            {isAdmin &&
                deprecatedInstalledApplications &&
                deprecatedInstalledApplications.length > 0 &&
                !isSelectedBoxOffline && (
                    <ListItemButton onClick={triggerApplicationUpdate}>
                        <ListItemIcon>
                            <Update />
                        </ListItemIcon>
                        <ListItemText primary={i18n.tc('menu.updateApplications')} />
                    </ListItemButton>
                )}
        </>
    );
};

Menu.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        rootAdmin: PropTypes.bool.isRequired,
    }),
    isSelectedBoxOffline: PropTypes.bool,
    selectedProductUuid: PropTypes.string.isRequired,
    deprecatedInstalledApplications: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateApplicationFn: PropTypes.func.isRequired,
};

Menu.defaultProps = {
    user: null,
    isSelectedBoxOffline: false,
};

export default connect(
    (state) => ({
        isAdmin: state.accesses.isAdmin,
        selectedProductUuid: state.accesses.selectedProductUuid,
        user: state.accesses.user,
        isSelectedBoxOffline: state.accesses.isSelectedBoxOffline,
        deprecatedInstalledApplications: getDeprecatedInstalledApplications(state),
    }),
    (dispatch) => ({
        updateApplicationFn: (appUuid) => dispatch(updateApplication(appUuid)),
    })
)(Menu);
