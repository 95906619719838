import { useI18n } from '@braincube/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Grid, styled } from '@mui/material';
import ReactSwipe from 'react-swipe';
import { advancedTextSearch } from '@braincube/ui';

import oee from '../../images/oee.jpeg';
import s7 from '../../images/s7.jpeg';
import controlCard from '../../images/control-card.jpg';
import mpt from '../../images/mpt.jpeg';
import cpmImage from '../../images/critical-parameters-monitoring.jpg';
import studio from '../../images/studio.jpg';
import AppDetail from './AppDetail';
import CarouselButtons from './CarouselButtons';
import NotInstalledApplication from './NotInstalledApplication';

import { getAvailableApplications } from '../../redux/modules/apps';
import { getInstalledApplicationsUuid } from '../../redux/helpers/apps';
import { getOffers } from '../../redux/modules/offers';

const StyledZoneSlideshow = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 2, 0, 2),
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
        width: '80%',
    },
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
        '& > div': {
            width: '100%',
        },
    },
}));

const StyledApplicationTitle = styled('div')(({ theme }) => ({
    margin: `0 200px ${theme.spacing(2)} 200px`,
    borderBottom: '1px solid #aaa',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
        margin: theme.spacing(2),
    },
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    padding: `0 200px`,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: theme.spacing(2),
    },
}));

const StyledNoResults = styled('div')({
    margin: 'auto',
});

const swipeOptions = { continuous: false };

const ContentComponent = ({
    getAvailableApplicationsFn,
    selectedProductUuid,
    accessList,
    availableApplications,
    installedApplicationsUuid,
    textFilter,
    categoryFilter,
    tagsFilter,
    offers,
    selectedAccessProductInfo,
    getOffersFn,
}) => {
    const [index, setIndex] = useState(0);
    const [reactSwipeEl, setReactSwipeEl] = useState(null);
    const i18n = useI18n();

    useEffect(() => {
        getAvailableApplicationsFn(selectedProductUuid);
        getOffersFn();
    }, [getAvailableApplicationsFn, getOffersFn, selectedProductUuid]);

    const selectedProductType = accessList.find(({ product }) => product.productId === selectedProductUuid).product
        .type;

    const getFilteredApps = useCallback(() => {
        let tmp = availableApplications
            .filter((app) => {
                if (selectedProductType === 'braincube') {
                    return app.productsTarget.includes('BRAINCUBE');
                }
                return app.productsTarget.includes('IOT');
            })
            .filter((app) => app.reachable === true)
            .filter((app) => installedApplicationsUuid.indexOf(app.id) === -1); // Hides applications that are already installed

        tmp = advancedTextSearch(textFilter, tmp, ['name', 'description']);

        return tmp
            .filter((app) => app.category === categoryFilter || categoryFilter === 'all') // Category filter
            .filter((app) => tagsFilter.every((elem) => app.tags.indexOf(elem) !== -1) || tagsFilter.length === 0); // Tags filter
    }, [availableApplications, categoryFilter, installedApplicationsUuid, selectedProductType, tagsFilter, textFilter]);

    const changeRef = useCallback((el) => setReactSwipeEl(el), []);

    const onDotClick = useCallback(
        (newIndex) => {
            reactSwipeEl.slide(newIndex);
            setIndex(newIndex);
        },
        [reactSwipeEl]
    );

    const renderAppsListIfNeeded = () => {
        // Get all products offers
        const productOffers = offers.filter((offer) => selectedAccessProductInfo.offers.includes(offer.id));

        // Get offers applications
        const apps = getFilteredApps();

        return (
            <>
                <StyledZoneSlideshow>
                    <div>
                        {selectedProductType === 'braincube' ? (
                            <ReactSwipe
                                key={selectedProductUuid}
                                className="carousel"
                                swipeOptions={swipeOptions}
                                ref={changeRef}
                            >
                                <img src={mpt} />
                                <img src={cpmImage} />
                            </ReactSwipe>
                        ) : (
                            <ReactSwipe
                                key={selectedProductUuid}
                                className="carousel"
                                swipeOptions={swipeOptions}
                                ref={changeRef}
                            >
                                <img src={studio} />
                                <img src={oee} />
                                <img src={controlCard} />
                                <img src={s7} />
                                <img src={mpt} />
                            </ReactSwipe>
                        )}
                        <CarouselButtons
                            index={index}
                            count={selectedProductType === 'braincube' ? 2 : 5}
                            onDotClick={onDotClick}
                        />
                    </div>
                </StyledZoneSlideshow>
                <StyledApplicationTitle>
                    <Typography variant="h6">{i18n.tc('allApplication.title')}</Typography>
                </StyledApplicationTitle>
                <StyledGridContainer container spacing={2}>
                    {apps.length === 0 && (
                        <StyledNoResults>
                            <Typography variant="h6">{i18n.tc('allApplication.noResults')}</Typography>
                        </StyledNoResults>
                    )}
                    {apps
                        .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        })
                        .sort((a, b) => {
                            const findAInOffer = productOffers.find((offer) => offer.apps.includes(a.id));
                            const isANotInOffer = !findAInOffer;

                            const findBInOffer = productOffers.find((offer) => offer.apps.includes(b.id));
                            const isBNotInOffer = !findBInOffer;

                            return isANotInOffer - isBNotInOffer;
                        })
                        .map((app) => {
                            let isNotInOffer;

                            if (app.custom) {
                                isNotInOffer = false;
                            } else {
                                isNotInOffer = !productOffers.find((offer) => offer.apps.includes(app.id));
                            }

                            return <NotInstalledApplication key={app.id} app={app} isNotInOffer={isNotInOffer} />;
                        })}
                </StyledGridContainer>
            </>
        );
    };

    return (
        <div>
            {renderAppsListIfNeeded()}
            <AppDetail />
        </div>
    );
};

ContentComponent.propTypes = {
    textFilter: PropTypes.string.isRequired,
    categoryFilter: PropTypes.string.isRequired,
    tagsFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableApplications: PropTypes.arrayOf(PropTypes.object),
    accessList: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedProductUuid: PropTypes.string.isRequired,
    getAvailableApplicationsFn: PropTypes.func.isRequired,
    selectedApp: PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }),
    installedApplicationsUuid: PropTypes.arrayOf(PropTypes.string).isRequired,
    offers: PropTypes.array.isRequired,
    selectedAccessProductInfo: PropTypes.object.isRequired,
    getOffersFn: PropTypes.func.isRequired,
};

ContentComponent.defaultProps = {
    selectedApp: null,
    availableApplications: [],
};

export default connect(
    (state) => ({
        isAdmin: state.accesses.isAdmin,
        textFilter: state.filters.text,
        categoryFilter: state.filters.category,
        tagsFilter: state.filters.tags,
        availableApplications: state.apps.list,
        accessList: state.accesses.list,
        selectedProductUuid: state.accesses.selectedProductUuid,
        selectedApp: state.apps.selected,
        installedApplicationsUuid: getInstalledApplicationsUuid(state),
        offers: state.offers.list,
        selectedAccessProductInfo: state.accesses.selectedAccessProductInfo,
    }),
    (dispatch) => ({
        getAvailableApplicationsFn: (productUuid) => dispatch(getAvailableApplications(productUuid)),
        getOffersFn: () => dispatch(getOffers()),
    })
)(ContentComponent);
