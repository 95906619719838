export function getInstalledApplicationsUuid(state) {
    const { selectedAccessProductInfo } = state.accesses;

    if (selectedAccessProductInfo === null) {
        return [];
    }

    return selectedAccessProductInfo.apps.map((app) => app.appId);
}

export function getDeprecatedInstalledApplications(state) {
    const { selectedAccessProductInfo } = state.accesses;

    if (selectedAccessProductInfo === null) {
        return [];
    }
    return selectedAccessProductInfo.apps.filter((app) => app.latest === false);
}

export function getInstalledVersionUuid(state) {
    const { list, selectedAccessProductInfo } = state.accesses;

    if (list.length === 0 || state.apps.selected === null) {
        return null;
    }

    const selectedProductInstalledApps = selectedAccessProductInfo.apps;

    if (selectedProductInstalledApps.length === 0) {
        return null;
    }

    const selectedApplicationIsInstalled = selectedProductInstalledApps.find(
        (app) => app.appId === state.apps.selected.id
    );

    if (!selectedApplicationIsInstalled) {
        return null;
    }

    return selectedApplicationIsInstalled.id;
}

export function getCategoriesList(state) {
    if (state.accesses.list.length === 0) {
        return [];
    }

    const selectedProductType = state.accesses.list.find(
        ({ product }) => product.productId === state.accesses.selectedProductUuid
    ).type;

    return [
        ...new Set(
            state.apps.list
                .filter((app) => {
                    if (selectedProductType === 'braincube') {
                        return app.type === 'BRAINCUBE_APP';
                    }
                    return ['NPM_REGISTRY', 'CDN'].includes(app.type);
                })
                .filter((app) => getInstalledApplicationsUuid(state).indexOf(app.id) === -1)
                .filter((app) => app.category)
                .map((app) => app.category)
                .sort((a, b) => a.localeCompare(b))
        ),
    ];
}

export function getTagsList(state) {
    if (state.accesses.list.length === 0) {
        return [];
    }

    const selectedProductType = state.accesses.list.find(
        ({ product }) => product.productId === state.accesses.selectedProductUuid
    ).product.type;

    return [
        ...new Set(
            state.apps.list
                .filter((app) => {
                    if (selectedProductType === 'braincube') {
                        return app.productsTarget.includes('BRAINCUBE');
                    }
                    return app.productsTarget.includes('IOT');
                })
                .filter((app) => getInstalledApplicationsUuid(state).indexOf(app.id) === -1)
                .map((app) => app.tags)
                .reduce((acc, val) => acc.concat(val), [])
                .sort((a, b) => a.localeCompare(b))
        ),
    ];
}
