import React, { Component } from 'react';

class GlobalErrorBoundary extends Component {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    render() {
        const { state, props } = this;
        if (state.hasError) {
            return (
                <div>
                    <h1>This application encountered an error.</h1>
                </div>
            );
        }

        return props.children;
    }
}

export default GlobalErrorBoundary;
