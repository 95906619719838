import Ws from '../../ws';

import { startFetching, stopFetching } from './app';

export const SET_OFFERS = 'app-store-front/app/SET_OFFERS';

const initialState = {
    list: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_OFFERS:
            return { ...state, list: action.payload };
        default:
            return state;
    }
}

export function setOffers(offers) {
    return {
        type: SET_OFFERS,
        payload: offers,
    };
}

export function getOffers() {
    return (dispatch, getState) => {
        const { selectedProductUuid } = getState().accesses;

        dispatch(startFetching());

        Ws.getOffers(selectedProductUuid)
            .then((offers) => {
                dispatch(setOffers(offers));
                dispatch(stopFetching());
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    };
}
