import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, styled } from '@mui/material';
import { connect } from 'react-redux';

const StyledLinearProgress = styled(LinearProgress)({
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: 'transparent',
    },
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#FFDD00',
    },
});

const Loader = ({ isFetching }) => {
    return isFetching ? <StyledLinearProgress /> : null;
};

Loader.propTypes = {
    isFetching: PropTypes.bool.isRequired,
};

export default connect((state) => ({ isFetching: state.app.isFetching }))(Loader);
