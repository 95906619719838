import Cookie from 'js-cookie';

const URL_MATCHER = /([a-zA-Z0-9_-]*\.*)*(\.[a-zA-Z0-9_-]*\.[a-zA-Z0-9_-]*)/;

export function getMainDomain(url = window.location.hostname) {
    const matches = URL_MATCHER.exec(url);
    if (matches) {
        return matches[matches.length - 1];
    }

    return null;
}

export function mergeOptionsWithDomain(options) {
    const domain = getMainDomain();

    if (!domain) {
        return options;
    }

    if (!options) {
        return { domain };
    }

    return { ...options, domain };
}

export function get(name, options) {
    return Cookie.get(name, mergeOptionsWithDomain(options));
}

export function set(name, value, options) {
    return Cookie.set(name, value, mergeOptionsWithDomain(options));
}

export default { get, set };
