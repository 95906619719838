import { useI18n } from '@braincube/i18n';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Typography, styled } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AccessPermissions } from '@braincube/ui';
import Ws from '../../../ws';

const profiles = [
    {
        label: 'Iplwadmin',
        value: 'IPLWADMIN',
    },
    {
        label: 'Coach',
        value: 'COACH',
    },
    {
        label: 'Administrator',
        value: 'ADMIN',
    },
    {
        label: 'Manager',
        value: 'MANAGER',
    },
    {
        label: 'Expert',
        value: 'EXPERT',
    },
    {
        label: 'User',
        value: 'USER',
    },
    {
        label: 'Custom',
        value: 'CUSTOM',
    },
];

const StyledLoaderWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-scrollPaper': {
        overflow: 'visible',
    },
});

const StyledDialogContent = styled(DialogContent)({
    overflow: 'visible',
});

const ShareDialog = ({ onClose, app, selectedProductUuid, selectedProductProviderName, userAccessList }) => {
    const [infos, setInfos] = useState(null);
    const [users, setUsers] = useState(null);
    const [selectedProfile, setSelectedProfile] = useState('public');
    const [isLoading, setIsLoading] = useState(false);
    const i18n = useI18n();

    const currentProfileAccess = useMemo(() => {
        return userAccessList
            .find(({ product }) => product.productId === selectedProductUuid)
            .accessRight.toLowerCase();
    }, [userAccessList, selectedProductUuid]);

    useEffect(() => {
        setIsLoading(true);

        Ws.getProductAccessList(selectedProductProviderName, selectedProductUuid).then(({ accessList }) => {
            Ws.getUsersList()
                .then((response) => {
                    const detailledUsers = accessList.map((access) => {
                        return {
                            ...response.users.find((user) => user.uuid === access.user.uuid),
                            profile: access.accessType.toLowerCase(),
                        };
                    });

                    setUsers(detailledUsers);
                })
                .finally(() => setIsLoading(false));
        });
    }, [selectedProductProviderName, selectedProductUuid]);

    useEffect(() => {
        Ws.getAppShareInfo(app.id, selectedProductUuid).then((receivedInfos) => {
            setInfos({
                ...receivedInfos,
                usersUuid: receivedInfos.usersUuid || [],
            });

            if (!receivedInfos.controlledLevel && receivedInfos.shareLevel === 'PRIVATE') {
                setSelectedProfile('private');
            } else {
                const appProfile = profiles.find((profile) => profile.value === receivedInfos.controlledLevel);

                setSelectedProfile(appProfile?.value.toLowerCase() || 'public');
            }
        });
    }, [app, selectedProductUuid]);

    const validate = useCallback(() => {
        let controlledLevel = selectedProfile.toUpperCase();
        let shareLevel = selectedProfile.toUpperCase() !== 'PUBLIC' ? 'CONTROLLED' : 'PUBLIC';

        if (selectedProfile.toUpperCase() === 'PRIVATE') {
            controlledLevel = null;
            shareLevel = 'PRIVATE';
        }

        Ws.updateAppShareInfo(app.id, selectedProductUuid, {
            ...infos,
            controlledLevel,
            shareLevel,
        }).then(onClose);
    }, [app.id, infos, onClose, selectedProductUuid, selectedProfile]);

    const onSelectedUsersChange = useCallback(
        (selectedUsers) => {
            setInfos({
                ...infos,
                usersUuid:
                    (selectedUsers &&
                        selectedUsers.map((user) => {
                            return users.find((userFromList) => userFromList.email === user.email).uuid;
                        })) ||
                    [],
            });
        },
        [infos, users]
    );

    const onSelectedProfileChange = useCallback((profile) => {
        setSelectedProfile(profile);
    }, []);

    return (
        <StyledDialog open onClose={onClose} maxWidth="xs" fullWidth>
            <StyledDialogContent>
                {isLoading && (
                    <StyledLoaderWrapper>
                        <CircularProgress />
                        <Typography paragraph>{i18n.tc('dialog.loading')}</Typography>
                    </StyledLoaderWrapper>
                )}

                {users && infos && (
                    <AccessPermissions
                        currentProfile={currentProfileAccess}
                        users={users.map((user) => ({
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            profile: user.profile,
                        }))}
                        selectedUsers={users
                            .filter((user) => infos.usersUuid.includes(user.uuid))
                            .map((user) => ({
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email,
                                profile: user.profile,
                            }))}
                        onSelectedUsersChange={onSelectedUsersChange}
                        selectedProfile={
                            typeof selectedProfile === 'string' ? selectedProfile : selectedProfile?.value.toLowerCase()
                        }
                        onSelectedProfileChange={onSelectedProfileChange}
                    />
                )}
            </StyledDialogContent>
            {!isLoading && (
                <DialogActions>
                    <Button onClick={validate}>{i18n.tc('common.save')}</Button>
                </DialogActions>
            )}
        </StyledDialog>
    );
};

ShareDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    app: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    userAccessList: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedProductUuid: PropTypes.string.isRequired,
    selectedProductProviderName: PropTypes.string.isRequired,
};

export default connect((state) => ({
    userAccessList: state.accesses.list,
    selectedProductUuid: state.accesses.selectedProductUuid,
    selectedProductProviderName: state.accesses.selectedProductProviderName,
}))(ShareDialog);
