import { fetchWithBearer, fetchWithProductBearer } from '@braincube/jwt-browser';

export default class Ws {
    static getProductInfo(productUuid) {
        return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/info`, {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject();
            })
            .then((info) => {
                return Promise.resolve(info);
            });
    }

    static getCustomApplication(productUuid) {
        return fetchWithBearer(
            `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apps/custom`,
            {
                headers: new Headers({
                    'x-product-id': productUuid,
                }),
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject();
            })
            .then((applications) => {
                return Promise.resolve(applications);
            });
    }

    static getAvailableApplications(productUuid) {
        return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apps`, {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject();
            })
            .then((applications) => {
                return Promise.resolve(applications);
            });
    }

    static applyChanges(productUuid, toInstallUuid, toDeleteUuid, forceRemove = false, toUpdateUuid) {
        const body = [];

        if (toInstallUuid) {
            body.push({
                appId: toInstallUuid,
                action: 'INSTALL',
            });
        }

        if (toDeleteUuid) {
            body.push({
                appId: toDeleteUuid,
                action: forceRemove ? 'FORCE_DELETE' : 'DELETE',
            });
        }

        if (toUpdateUuid) {
            if (Array.isArray(toUpdateUuid)) {
                toUpdateUuid.forEach((uuid) => {
                    body.push({
                        appId: uuid,
                        action: 'UPDATE',
                    });
                });
            } else {
                body.push({
                    appId: toUpdateUuid,
                    action: 'UPDATE',
                });
            }
        }

        return fetchWithBearer(
            `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apply`,
            {
                method: 'POST',
                headers: new Headers({
                    'x-product-id': productUuid,
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify(body),
            }
        ).then((response) => {
            if (response.ok) {
                return Promise.resolve();
            }
            return Promise.reject();
        });
    }

    static getOffers(productUuid) {
        return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/offers`, {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject();
        });
    }

    static getAppShareInfo(appId, productUuid) {
        return fetchWithBearer(
            `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/products/${productUuid}/share`,
            {
                headers: new Headers({
                    'x-product-id': productUuid,
                }),
            }
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject();
        });
    }

    static updateAppShareInfo(appId, productUuid, share) {
        return fetchWithBearer(
            `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/products/${productUuid}/share`,
            {
                headers: new Headers({
                    'x-product-id': productUuid,
                    'Content-Type': 'application/json',
                }),
                method: 'PUT',
                body: JSON.stringify(share),
            }
        );
    }

    static getProductAccessList(provider, productUuid) {
        return fetchWithBearer(
            `https://${Ws.getSsoPage()}/sso-server/api/v2/provider/${provider}/product/${productUuid}/grant`
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject();
        });
    }

    static getUsersList() {
        return fetchWithBearer(`https://${Ws.getSsoPage()}/sso-server/api/v2/user`).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject();
        });
    }

    static logout() {
        fetchWithBearer(`https://${Ws.getSsoPage()}/sso-server/ws/session/logout`).then((response) => {
            if (response.ok) {
                window.location.assign(`https://${Ws.getSsoPage()}/?redirect=${window.location.href}`);
            }
        });
    }

    static getSsoPage() {
        // eslint-disable-next-line no-prototype-builtins
        if (
            window.location.hostname.includes('.blu-e.fr') &&
            Object.prototype.hasOwnProperty.call(window.BC_API_ENDPOINTS_CONF, 'baseBlue')
        ) {
            return window.BC_API_ENDPOINTS_CONF.baseBlue;
            // eslint-disable-next-line no-prototype-builtins
        }
        if (Object.prototype.hasOwnProperty.call(window.BC_API_ENDPOINTS_CONF, 'sso')) {
            return window.BC_API_ENDPOINTS_CONF.sso;
        }
        return window.BC_API_ENDPOINTS_CONF.base;
    }

    static getZip(productUuid) {
        return fetchWithProductBearer(`https://${window.BC_API_ENDPOINTS_CONF.fileManager}/zip`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                id: productUuid,
            }),
        }).then((response) => {
            if (response.ok) {
                const link = document.createElement('a');
                link.href = `https://${window.BC_API_ENDPOINTS_CONF.fileManager}/zip-storage/${response.headers.get(
                    'Location'
                )}`;
                link.download = response.headers.get('Location');
                link.click();
                return Promise.resolve();
            }
            return Promise.reject();
        });
    }

    static getBranding(productUuid) {
        return fetchWithProductBearer(
            `https://${window.BC_API_ENDPOINTS_CONF.sso}/sso-server/api/v2/branding/product/${productUuid}?view=full`
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }

            return Promise.reject();
        });
    }
}
