export const SET_TEXT = 'app-store/filters/SET_TEXT';
export const SET_CATEGORY = 'app-store/filters/SET_CATEGORY';
export const SET_TAGS = 'app-store/filters/SET_TAGS';

const initialState = {
    text: '',
    category: 'all',
    tags: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_TEXT:
            return { ...state, text: action.payload };
        case SET_CATEGORY:
            return { ...state, category: action.payload };
        case SET_TAGS:
            return { ...state, tags: action.payload };
        default:
            return state;
    }
}

export function setText(text) {
    return {
        type: SET_TEXT,
        payload: text,
    };
}

export function setCategory(category) {
    return {
        type: SET_CATEGORY,
        payload: category,
    };
}

export function setTags(tags) {
    return {
        type: SET_TAGS,
        payload: tags,
    };
}

export function updateTags(tag) {
    return (dispatch, getState) => {
        const { tags } = getState().filters;

        if (tags.indexOf(tag) !== -1) {
            return dispatch(setTags(tags.filter((active) => active !== tag)));
        }
        return dispatch(setTags([...tags, tag]));
    };
}

export function resetFilter() {
    return (dispatch) => {
        dispatch(setText(initialState.text));
        dispatch(setTags(initialState.tags));
        dispatch(setCategory(initialState.category));
    };
}
