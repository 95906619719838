import { Client } from '@stomp/stompjs';

import Ws from '../../ws';

import { getAccessProductInfo } from './accesses';
import { resetFilter } from './filters';

export const START_FETCHING = 'app-store-front/app/START_FETCHING';
export const STOP_FETCHING = 'app-store-front/app/STOP_FETCHING';
export const SET_STOMP_CLIENT = 'app-store-front/app/SET_STOMP_CLIENT';
export const SET_SUBSCRIPTION = 'app-store-front/app/SET_SUBSCRIPTION';

const initialState = {
    isFetching: false,
    error: false,
    stompClient: null,
    subscription: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case START_FETCHING:
            return { ...state, isFetching: true };
        case STOP_FETCHING:
            return { ...state, isFetching: false };
        case SET_STOMP_CLIENT:
            return { ...state, stompClient: action.payload };
        case SET_SUBSCRIPTION:
            return { ...state, subscription: action.payload };
        default:
            return state;
    }
}

export function startFetching() {
    return {
        type: START_FETCHING,
    };
}

export function stopFetching() {
    return {
        type: STOP_FETCHING,
    };
}

export function installApplication(appId) {
    return (dispatch, getState) => {
        const { selectedProductUuid } = getState().accesses;

        dispatch(startFetching());

        Ws.applyChanges(selectedProductUuid, appId, null, null, null)
            .then(() => {
                dispatch(getAccessProductInfo());
                dispatch(resetFilter());
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    };
}

export function removeApplication(appId, forceRemove) {
    return (dispatch, getState) => {
        const { selectedProductUuid } = getState().accesses;

        dispatch(startFetching());

        Ws.applyChanges(selectedProductUuid, null, appId, forceRemove, null)
            .then(() => {
                dispatch(getAccessProductInfo());
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    };
}

export function updateApplication(appId) {
    return (dispatch, getState) => {
        const { selectedProductUuid } = getState().accesses;

        dispatch(startFetching());

        Ws.applyChanges(selectedProductUuid, null, null, null, appId)
            .then(() => {
                dispatch(getAccessProductInfo());
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    };
}

export function setStompClient(client) {
    return {
        type: SET_STOMP_CLIENT,
        payload: client,
    };
}

export function setSubscription(subscription) {
    return {
        type: SET_SUBSCRIPTION,
        payload: subscription,
    };
}

export function initStompClient() {
    return (dispatch) => {
        const client = new Client({
            brokerURL: `wss://${window.BC_API_ENDPOINTS_CONF.appsManager}/websocket`, // eslint-disable-line no-undef
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
        });

        client.onConnect = () => {
            // Do something, all subscribes must be done is this callback
            // This is needed because this will be executed after a (re)connect
            dispatch(setStompClient(client));
        };

        client.onStompError = (frame) => {
            // Will be invoked in case of error encountered at Broker
            // Bad login/passcode typically will cause an error
            // Complaint brokers will set `message` header with a brief message. Body may contain details.
            // Compliant brokers will terminate the connection after any error
            // eslint-disable-next-line no-console
            console.log(`Broker reported error: ${frame.headers.message}`);
            // eslint-disable-next-line no-console
            console.log(`Additional details: ${frame.body}`);
        };

        client.activate();
    };
}
