const FadeInQuickAction = {
    from: {
        opacity: 0,
    },
    to: {
        opacity: 1,
    },
};

export default FadeInQuickAction;
